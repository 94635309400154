// Woocommerce scripts
/* global ajax_object */
$(() => {
  /*const $body = $('body');
const isWoocommercePage = $body.hasClass('woocommerce-page');
const isCheckoutPage = $body.hasClass('woocommerce-checkout');
const isCartPage = $body.hasClass('woocommerce-cart');*/
  const $woocommerce = $('.woocommerce');

  // -/+ buttons for a product amount input.
  let add2CartTimer;
  $woocommerce.on(
    'click',
    '.js-add-to-cart-plus, .js-add-to-cart-minus',
    (event) => {
      let $this = $(event.currentTarget);

      // Get current quantity values.
      let $qty;

      if (!$('body').hasClass('woocommerce-cart')) {
        // for .cart (the single product page).
        $qty = $this.closest('form.cart').find('input[type="number"].qty');
      } else {
        // for .woocommerce-cart-form (the cart page).
        $qty = $this
          .closest('.js-cart-quantity')
          .find('input[type="number"].qty');
      }

      // Add2cart popup
      if (!$qty.length) {
        $qty = $this
          .closest('.js-add2cart-quantity')
          .find('input[type="number"].qty');
      }

      let val = parseFloat($qty.val());
      let max = $qty.attr('max') ? parseFloat($qty.attr('max')) : 9999;
      let min = $qty.attr('min') ? parseFloat($qty.attr('min')) : 0;
      let step = $qty.attr('step') ? parseFloat($qty.attr('step')) : 1;

      // Change the value if plus or minus.
      if ($this.is('.js-add-to-cart-plus')) {
        if (max && max <= val) {
          $qty.val(max);
        } else {
          $qty.val(val + step);
        }
      } else {
        if (min && min >= val) {
          $qty.val(min);
        } else if (val > 0.5) {
          $qty.val(val - step);
        }
      }

      $qty.trigger('input');

      clearTimeout(add2CartTimer);

      add2CartTimer = setTimeout(updateCartProductQuantity, 600);

      $('button[name="update_cart"]').trigger('click');
    }
  );

  // Main document ready
  $(document).ready(function () {
    // Free gift product functionality
    $('#cart-free-product').on('change', function () {
      let checkbox = $(this),
        id = checkbox.data('id');

      let data = {
        action: 'wc_add_to_cart',
        product_id: id,
      };
      console.log(data);
      $.ajax({
        // you can also use $.post here
        url: ajax_object.ajax_url, // AJAX handler
        data: data,
        type: 'POST',
        beforeSend: function () {
          // button.text('Loading...'); // change the button text, you can also add a preloader image
        },
        success: function () {
          $(document.body).trigger('wc_fragment_refresh');
          $(document.body).trigger('added_to_cart');
          setTimeout(function () {
            checkbox.prop('disabled', false);
          }, 200);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          // For debugging, console log any errors. In production, do something different.
          console.log(xhr.status);
          console.log(thrownError);
        },
      });
    });

    // Trigger checkout if address is changed
    triggerUpdateCheckout();
    triggerUpdateCheckout('shipping');

    // Update address fields if hidden
    $(document.body).on('update_checkout', function () {
      prefillAddress();
      prefillAddress('shipping');
    });

    // Check if address has number
    $(document.body).on('updated_checkout', function () {
      prefillAddress();
      prefillAddress('shipping');
      validateAddressField();
      validateAddressField('shipping_address_1');
    });

    $(document.body).on('validate', 'form.checkout', function () {
      validateAddressField();
      validateAddressField('shipping_address_1');
    });

    function validateAddressField($fileId = 'billing_address_1') {
      let $billingAddress = $(document).find('#' + $fileId);
      const regex = /\d+/;

      if ($billingAddress.length && $billingAddress.val()) {
        if (!$billingAddress.val().match(regex)) {
          $billingAddress
            .closest('.validate-required')
            .removeClass('woocommerce-validated')
            .addClass('woocommerce-invalid');
        }
      }
    }

    function prefillAddress($type = 'billing') {
      let $wrapper = $(document).find('#' + $type + '_address_1_field:hidden');
      let $addressInput = $(document).find('#' + $type + '_address_1');

      if ($wrapper.length && $addressInput.length) {
        let address = '';
        let streetName = $(document).find('#' + $type + '_street_name');
        let streetNum = $(document).find('#' + $type + '_house_number');
        let streetNumSuffix = $(document).find(
          '#' + $type + '_house_number_suffix'
        );

        if (streetName.length) {
          address += streetName.val();
        }
        if (streetNum.length) {
          address += ' ' + streetNum.val();
        }
        if (streetNumSuffix.length && streetNumSuffix.val()) {
          address += '-' + streetNumSuffix.val();
        }

        // Put full address to the field
        $addressInput.val(address).trigger('change');
      }
    }

    function triggerUpdateCheckout($type = 'billing') {
      $(document).on(
        'change',
        '#' +
          $type +
          '_street_name, #' +
          $type +
          '_house_number, #' +
          $type +
          '_house_number_suffix, #' +
          $type +
          '_postcode',
        function () {
          if (
            $(document).find('#' + $type + '_address_1_field:hidden').length
          ) {
            $(document.body).trigger('update_checkout');
          }
        }
      );
    }
  });

  // Remove responsive styles
  $('body.woocommerce-cart')
    .find('.cart_totals .shop_table')
    .removeClass('shop_table_responsive');

  // Set min value to the quantity as the default one.
  $(() => {
    let $qty = !$('body').hasClass('woocommerce-cart')
      ? $('form.cart').find('input[type="number"].qty')
      : null;
    if ($qty) {
      let min = $qty && $qty.attr('min') ? parseFloat($qty.attr('min')) : 1;
      $qty.val(min);
    }
  });

  // Product add2cart popup archives
  $('body.has-add2cart').on('click', '.ajax_add_to_cart', (event) => {
    event.preventDefault();
    event.stopPropagation();

    let $this = $(event.currentTarget);
    let product_id = $this.data('product_id');
    let quantity = $this.data('quantity');

    if (product_id === undefined || quantity === undefined) return false;

    addToCartHandler(product_id, quantity);
  });

  // Product add2cart ajax
  $('body.has-add2cart').on('click', '.single_add_to_cart_button', (event) => {
    event.preventDefault();
    event.stopPropagation();

    let $this = $(event.currentTarget);
    let $form = $this.closest('form.cart');

    $this.addClass('loading');

    if ($form.length) {
      let product_id = $form.hasClass('variations_form')
        ? $form.find('input[name="product_id"]').val()
        : $this.val();
      let variation_id = $form.hasClass('variations_form')
        ? $form.find('input[name="variation_id"]').val()
        : 0;
      let quantity = $form.find('input[name="quantity"]').val();
      let composite_options = $form.attr('data-options');

      addToCartHandler(product_id, quantity, variation_id, composite_options);
    }
  });

  // Close trigger
  $(document).on('click', '.js-add2cart-close', (e) => {
    e.preventDefault();
    e.stopPropagation();

    addToCartTrigger(); // close popup
  });

  // Click outside event
  $(document).on('click', (e) => {
    let $target = $(e.target);

    if (!$target.closest('.js-add2cart-inner').length) {
      addToCartTrigger(); // close popup
    }
  });

  // Add2cart ajax handler
  function addToCartHandler(
    product_id = false,
    quantity = 1,
    variation_id = 0,
    composite_options = ''
  ) {
    if (!product_id) return false;

    const $addToCartPopup = $(document).find('#website-add2cart');
    let $add2cartContent = $addToCartPopup.find('.js-add2cart-content');

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'website_wc_add_to_cart',
        product_id: product_id,
        variation_id: variation_id,
        attributes: getSelectedVariationAttributes(),
        quantity: quantity,
        composite_components: composite_options,
        wp_nonce: ajax_object.nonce,
      },
      success: function (res) {
        if ($add2cartContent.length && res.html) {
          $add2cartContent.html(res.html);
          initProductsSlider();
        }

        if (res.error || res.success === false) {
          $addToCartPopup.addClass('has-error');
        }

        addToCartTrigger(true);

        refreshCartFragments();
      },
      error: (error) => {
        console.log('$.ajax.error()');
        console.log(error);
        $addToCartPopup.addClass('has-error');
      },
      complete: function () {
        $('.single_add_to_cart_button').removeClass('loading');
      },
    });
  }
  function addToCartTrigger($show = false) {
    let $addToCartPopup = $(document).find('#website-add2cart');
    let $add2cartContent = $addToCartPopup.find('.js-add2cart-content');

    if ($addToCartPopup.length === 0) return false;

    if ($show) {
      $addToCartPopup.fadeIn().addClass('is-open');
    } else {
      $addToCartPopup.removeClass('has-error');
      $addToCartPopup
        .removeClass('is-open')
        .delay(150)
        .fadeOut('fast', () => {
          $add2cartContent.html('');
        });
    }
  }

  // Refreshing cart fragments
  function refreshCartFragments() {
    $.ajax({
      url: ajax_object.ajax_url, // WooCommerce's AJAX URL
      type: 'GET',
      data: {
        action: 'woocommerce_get_refreshed_fragments',
      },
      success: function (response) {
        if (response && response.fragments) {
          // Loop through all the refreshed fragments and replace them in the DOM
          $.each(response.fragments, function (key, value) {
            $(key).replaceWith(value);
          });
        }
      },
      error: function () {
        console.error('Error refreshing cart fragments.');
      },
    });
  }

  // Update product quantity
  function updateCartProductQuantity() {
    const $addToCartPopup = $(document).find('#website-add2cart');
    let $cartItem = $addToCartPopup.find('.website-add2cart-item');
    let cartItemKey = $cartItem.data('cart_item_key');
    let quantity = $cartItem.find('input[type="number"].qty').val();

    if (cartItemKey === undefined || quantity === undefined) return false;

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'website_wc_update_cart_item_quantity',
        cart_item_key: cartItemKey,
        quantity: quantity,
        wp_nonce: ajax_object.nonce,
      },
      beforeSend: function () {
        $cartItem.addClass('flex-loading');
      },
      success: function (res) {
        console.log(res);

        if (res.error || res.success === false) {
          $addToCartPopup.addClass('has-error');
        } else {
          $addToCartPopup.removeClass('has-error');
        }
      },
      error: (error) => {
        console.log('$.ajax.error()');
        console.log(error);
        $addToCartPopup.addClass('has-error');
      },
      complete: function () {
        $cartItem.removeClass('flex-loading');

        refreshCartFragments();
      },
    });

    return true;
  }

  // Init product slider
  function initProductsSlider() {
    const $addToCartPopup = $(document).find('#website-add2cart');
    const $productsSlider = $addToCartPopup.find(
      '.js-add2cart-slider:not(.slick-initialized)'
    );

    if ($productsSlider) {
      $productsSlider.slick({
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,

        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      setTimeout(() => {
        $(document).find('.matchHeight').matchHeight();
      }, 200);
    }
  }

  // Variable product get selected variation attributes
  function getSelectedVariationAttributes() {
    let selectedAttributes = {};

    // Loop through each select input for variation attributes
    $('form.variations_form select').each(function () {
      let attributeName = $(this).data('attribute_name'); // Get the attribute name (e.g., 'pa_color')
      let attributeValue = $(this).val(); // Get the selected value of that attribute

      if (attributeValue) {
        selectedAttributes[attributeName] = attributeValue;
      }
    });

    return selectedAttributes;
  }

  /*function getAttributeOptions(component_id = 0){
    if (!component_id) return false;
    let $component = $(document).find('.component_content[data-product_id="' + component_id + '"]')
    if ($component.length === 0) return false;
    let $attributes = $component.find('table.variations .attribute_options');
    if ($attributes.length === 0) return false;
    let attribute_options = {};

    $attributes.each((i, el) => {
      let $selector = $(el).find('select[data-attribute_name]');
      if ($selector.length) {
        attribute_options[$selector.data('attribute_name')] = $selector.val();
      }
    });

    return attribute_options;
  }*/
});
